import React, { useEffect } from "react"
import AOS from "aos"
import Episode from "../Episode/Episode"

import episodes from "../../data/episodes.json"
import "aos/dist/aos.css"
import "./Podcast.scss"

const Podcast = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 800,
      easing: "ease-in-out",
      offset: -100,
    })
  })

  return (
    <div id="podcasts-page">
      <h1 className="title" data-aos="fade-up">
        Podcast
      </h1>
      <div className="podcast-episodes">
        {episodes.map((episode, index) => (
          <div
            data-aos="fade-up"
            data-aos-delay={50 + 50 * ((index % 4) + 1)}
            key={episode.id}
            className="episode-container"
          >
            <Episode
              id={episode.id}
              image={episode.image}
              name={episode.name}
              description={episode.description}
              date={episode.date}
              duration={episode.duration}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Podcast
