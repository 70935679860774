import React from "react"

import play_button from "../../images/icons/play-buttonv2.svg"
import "./episode.scss"

const Episode = ({ id, image, name, description, date, duration }) => {
  const months = [
    "Janeiro ",
    "Fevereiro ",
    "Março ",
    "Abril ",
    "Maio ",
    "Junho ",
    "Julho ",
    "Agosto ",
    "Setembro ",
    "Outubro ",
    "Novembro ",
    "Dezembro ",
  ]
  const dateObj = new Date(date)
  const currentYear = new Date().getFullYear()
  const episodeYear = dateObj.getFullYear()
  const formatedDate =
    dateObj.getDate() +
    " de " +
    months[dateObj.getMonth()] +
    (episodeYear !== currentYear ? episodeYear : "")

  return (
    <div className="episode">
      <a href={`/episode?id=${id}`} className="img-container">
        <img
          className="thumb"
          src={image}
          alt={"Thumbnail do episodio " + name}
        />
        <img className="play-button" src={play_button} alt="" />
      </a>
      <div className="text-info">
        <a href={`/episode?id=${id}`}>
          <h2 className="episode-name">{name}</h2>
        </a>
        <p className="episode-description">{description}</p>
        <p className="datatime">{formatedDate.trim() + " • " + duration}</p>
      </div>
    </div>
  )
}

export default Episode
