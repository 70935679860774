import React from "react"
import { Helmet } from "react-helmet"

import RDStationConection from "../utils/RDStationConnection"
import PodcastComponent from "../components/Podcast/Podcast"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import NeoronWidget from "../utils/NeoronWidget"

export default function Podcast() {
  React.useEffect(() => {
    RDStationConection.init()
    NeoronWidget.init()
    // document.getElementById("label").style.display = "none"
  }, [])

  return (
    <div style={{ width: "100%" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Podcast | FABWORK</title>
        <link rel="canonical" href="https://fab.work/podcasts/" />
      </Helmet>
      <Header />
      <PodcastComponent />
      <Footer />
    </div>
  )
}
